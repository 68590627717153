import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import img_danger from "../../assets/img/danger.png";

export default function Error() {
  const error = useSelector((state) => state.error);

  return (
    <main className="flex flex-col items-center px-3 my-6">
      <div className="card p-4 w-full md:w-1/2">
        <img src={img_danger} alt="" className="md:w-20 block mx-auto" />
        <h1 className="text-center font-bold text-2xl my-4">
          Ha ocurrido un error
        </h1>
        <p className="text-center">{error}</p>
      </div>

      <Link to="/" className="link-primary mt-4">
        Volver
      </Link>
    </main>
  );
}
