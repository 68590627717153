import { useState } from "react";
import { useParams, Link } from "react-router-dom";
// import { CARD } from '../../data/card'
import { firebase } from "../../config/firebase";
// import { setError } from "../../global/actions";
import VerificationInput from "react-verification-input";

const Validate = () => {
  const { id } = useParams();
  const [pin, setPin] = useState("");
  const [validado, setValidado] = useState(false);
  const [loading, setLoading] = useState(false);
  const [codFake, setCodFake] = useState("");

  const getCode = (info) => {
    const validateCard = firebase
      .functions()
      .httpsCallable("validateVirtualCard");

    validateCard({ client: info.client, idLote: info.idLote, code: info.code })
      .then((res) => {
        setCodFake(res.data.code);
        setValidado(true);
        setLoading(false);
      })
      .catch((err) => {
        alert("Ha ocurrido un error");
        setLoading(false);
      });
  };

  const handleScan = (data) => {
    if (data) {
      let split = id.split("-");
      let splitPin = `${data.substring(0, 3)}-${data.substring(3)}`;

      if (splitPin.length < 7) {
        alert("Ingrese un código válido");
      } else {
        setLoading(true);

        const validCardPin = firebase
          .app()
          .functions("us-central1")
          .httpsCallable("validateVirtualCard");
        validCardPin({
          client: split[0],
          idLote: split[1],
          code: split[2],
          pin: splitPin,
        })
          .then((res1) => {
            console.log(res1);
            getCode({
              client: split[0],
              idLote: split[1],
              code: split[2],
            });
          })
          .catch((err) => {
            console.log("😎", err);
            alert("Ha ocurrido un error");
            setLoading(false);
          });
      }
    }
  };

  return (
    <section className="flex flex-col items-center px-3 my-6">
      <div className="card p-4 w-full md:w-1/2">
        {loading ? (
          <div className="flex justify-center my-12">
            <svg
              className="animate-spin -ml-1 mr-3 h-10 w-10 text-blue"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          </div>
        ) : (
          <>
            {!validado ? (
              <>
                <div className="form-group">
                  <label className="form-label">
                    Ingrese el código de seguridad de la tarjeta.
                  </label>
                  <VerificationInput
                    classNames={{
                      character: "form-control",
                      container: "h-[65px]",
                    }}
                    placeholder=""
                    validChars="0-9"
                    length={6}
                    onChange={(e) => setPin(e)}
                  />
                </div>

                <button
                  className="btn-primary block md:mx-auto w-full max-w-lg"
                  onClick={() => handleScan(pin)}
                >
                  Consultar
                </button>
              </>
            ) : (
              <>
                <p className="text-center">Gracias.</p>

                <div className="flex justify-center w-full text-3xl my-6">
                  <input
                    className="w-full form-control text-center uppercase"
                    value={codFake}
                    disabled
                  />
                </div>

                <button
                  className="btn-primary block my-4 md:mx-auto w-full md:w-1/2"
                  onClick={() => navigator.clipboard.writeText(codFake)}
                >
                  Copiar código
                </button>
              </>
            )}
          </>
        )}
      </div>

      <Link to={`/${id}`} className="link-primary mt-4">
        Volver
      </Link>
    </section>
  );
};

export default Validate;
