import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import Home from '../views/home/home'
import CardDetails from '../views/cards/cardDetails'
import Error from '../views/error/error';
import Exchange from '../views/exchange/exchange';
import Validate from '../views/validate/validate';

const AppRouter = () => {
    return (
        <BrowserRouter>
            <Navbar />
            <Routes>
                <Route exact path="/" element={<Home />} />
                <Route exact path="/:id" element={<CardDetails />} />
                <Route exact path="/error" element={<Error />} />
                <Route exact path="/:id/exchange" element={<Exchange />} />
                <Route exact path="/:id/validate" element={<Validate />} />
            </Routes>
            <Footer />
        </BrowserRouter>
    );

}

export default AppRouter