import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { firebase } from "../../config/firebase";
import { QrReader } from "react-qr-reader";
import { Player } from "@lottiefiles/react-lottie-player";

const Home = () => {
  const navigate = useNavigate();
  const [code, setCode] = useState("");
  const [loading, setLoading] = useState(false);

  const handleScan = (data) => {
    if (data) {
      let split = data.split("-");
      if (split.length !== 3) {
        alert("Ingrese un código válido");
      } else {
        setLoading(true);
        const validCardPin = firebase
          .app()
          .functions("us-central1")
          .httpsCallable("validCard");

        validCardPin({
          client: split[0],
          idLote: split[1],
          code: split[2],
        })
          .then((res1) => {
            console.log(res1);
            if (res1.data.status) {
              console.log(res1);
              navigate(`/${data}`);
            } else {
              // alert("La tarjeta no es valida")
            }
            setLoading(false);
          })
          .catch((err) => {
            setLoading(false);
            // dispatch(
            //     setError("La tarjeta ingresada no es valida")
            // ); else {
            // alert("La tarjeta no es valida");
            // }k
            // navigate("/error");
          });
      }
    }
  }; 

  return (
    <main className="flex flex-col items-center px-3 my-6">
      <div className="card p-4 w-full md:w-1/2">
        {loading ? (
          <section className="flex justify-center my-12">
            <svg
              className="animate-spin -ml-1 mr-3 h-10 w-10 text-blue"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          </section>
        ) : (
          <>
            <h1 className="text-center font-bold text-2xl">Usuario</h1>
            <p className="text-center">
              Escanee el código QR o ingrese el código de la tarjeta.
            </p>

            <div className="relative">
              <QrReader
                className="w-full border rounded-xl my-4 max-w-lg mx-auto"
                delay={300}
                onResult={(result, error) => {
                  if (!!result) {
                    let reg = /^https?:\/\/[\w\-]+(\.[\w\-]+)+[/#?]/;
                    let code = result.text.replace(reg, "");
                    setCode(code);
                  }

                  // if (!!error) {
                  //     handleError(error);
                  //     console.info(error);
                  // }
                }}
                // style={{ width: 5, height: 5 }}
              />

              <div className="absolute top-0 left-0 w-full h-full">
                <div className="flex justify-center items-center h-full">
                  <Player
                    autoplay
                    src="https://assets3.lottiefiles.com/packages/lf20_q30c1wrm.json"
                    // src="https://assets6.lottiefiles.com/packages/lf20_uwpcqber.json"
                    style={{ height: "300px", width: "300px" }}
                  ></Player>
                </div>
              </div>
            </div>

            <div className="form-group">
              <label className="form-label">Código de la Tarjeta</label>
              <input
                className="form-control max-w-lg"
                value={code}
                onChange={(e) => setCode(e.target.value)}
              />
            </div>

            <button
              className="btn-primary block md:mx-auto w-full max-w-lg"
              onClick={() => handleScan(code)}
            >
              Ingresar
            </button>
          </>
        )}
      </div>
    </main>
  );
};

export default Home;
